<template>
  <div class="center">
    <div class="company">
      <img :src="companyBackground" class="company-background">
      <div class="company-main">
        <div class="title">
          数源，助力企业数字化建设
        </div>
        <div class="text-first">
          一个综合集成服务平台，全面了解企业信息生命线
        </div>
        <div class="text-second">
          在医疗、教育、政府、能源、企业、运营商等多场景提供高效可靠的数字化解决方案，帮助企业保护和管理其宝贵的数据资产
        </div>
        <div class="detail">
          <div class="detail-item">
            <img :src="company1" class="company1">
            <div class="desc">
              <div>数智管理一体化</div>
              <div class="desc-next">业务场景全覆盖</div>
            </div>
            <div class="desc-second">

            </div>
          </div>
          <div class="detail-item">
            <img :src="company2" class="company2">
            <div class="desc">
              <div>运维效率倍增长</div>
              <div class="desc-next">企业成本急降低</div>
            </div>
          </div>
          <div class="detail-item">
            <img :src="company3" class="company3">
            <div class="desc">
              <div>技术推动发展</div>
              <div class="desc-next">引领数智时代</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="solution">
      <img class="background" :src="solutionBackground "/>
      <div class="solution-main">
        <div class="title">
          三大系列，提供一站式解决方案
        </div>
        <div class="solution-tab">
          <el-tabs tab-position="left" class="tab">
            <el-tab-pane label="信息智能备">
              <div class="tab-title">
				  云融云备LinkedStor Backup
              </div>
              <div class="tab-text">
                利用自主研发的数据保护技术，打造“云融云备”数据服务品牌，构建“备份、容灾、存储”三大功能体系，最大程度提高数据安全系数的同时实现存储“瘦身”，云融云备让数据从保值到增值。
              </div>
              <div class="buttonRoute">
                <el-button @click="toRoute('eBackup')">了解更多</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="数据一键迁">
				<div class="eMove">
					<div class="tab-title">
						数联整机云迁移LinkedStor CMove
					</div>
					<div class="eMoveTab-text">
						基于科技时代数据需要高效利用，“数联整机云迁移”系列产品打破传统，致力于解决混合云环境与异地载体的一键迁移/测试/恢复难题，实现数据瞬间迁移，轻松上云，灵活流转。
					</div>
					<div class="eMoveButtonRoute">
						<el-button @click="toRoute('eMigrate')">了解更多</el-button>
					</div>
				</div>
				<div class="eMoveTool">
					<div class="tab-title">
						数联e键搬家工具LinkedStor ALLMove
					</div>
					<div class="eMoveTab-text">
						简化迁移过程，确保数据安全无损！数联e键搬家工具LinkedStor ALLMove让您轻松、快速地将应用和数据从任何源环境迁移到目标环境，实现无缝过渡，提升业务效率，减少停机时间，是您进行无缝迁移的必备利器！
					</div>
					<div class="eMoveButtonRoute">
						<el-button @click="toRoute('eMove')">了解更多</el-button>
					</div>
				</div>
            </el-tab-pane>
            <el-tab-pane label="管理智能化">
              <div class="tab-title">
				  数联云桌面系统LinkedStor Yundy
              </div>
              <div class="tab-text">
                选用领先的终端设备，支持多种终端登录访问，即装即用，为用户搭建搭建“集中管理、统一便捷”智能办公、学习环境。
              </div>
              <div class="buttonRoute">
                <el-button @click="toRoute('eManage')">了解更多</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="map-title">
        为全国用户构建高效的IT轻资产保护体系
      </div>
      <img :src="map" class="map-img">
    </div>
    <div class="honor">
      <img class="honor-background" :src="background"/>
      <div class="text">
        丰富的行业实践企业资质
      </div>
      <div class="honor-list">
        <div class="honor-item1">
          <img :src="honor1" class="honor-img1">
          <div class="honor-text1">国家高新技术认证企业</div>
        </div>
        <div class="honor-item">
          <img :src="honor2" class="honor-img2">
          <div class="honor-text2">ISO9001</div>
          <div class="honor-text2">质量管理体系认证证书</div>
        </div>
        <div class="honor-item">
          <img :src="honor3" class="honor-img3">
          <div class="honor-text3">企业信用等级3A证书</div>
        </div>
        <div class="honor-item">
          <img :src="honor4" class="honor-img4">
          <div class="honor-text4">广东软件行业协会会员证</div>
        </div>
        <div class="honor-item5">
          <img :src="honor5" class="honor-img5">
          <div class="honor-text5">ISO14001</div>
          <div class="honor-text5">环境管理体系认证证书</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      background: require("../../assets/home-center/honor-background.png"),
      honor1: require("../../assets/home-center/honor1.png"),
      honor2: require("../../assets/home-center/honor2.png"),
      honor3: require("../../assets/home-center/honor3.png"),
      honor4: require("../../assets/home-center/honor4.png"),
      honor5: require("../../assets/home-center/honor5.png"),
      map: require("../../assets/home-center/map.png"),
      solutionBackground: require("../../assets/home-center/solution-background.png"),
      companyBackground: require("../../assets/home-center/company-background.png"),
      company1: require("../../assets/home-center/company1.png"),
      company2: require("../../assets/home-center/company2.png"),
      company3: require("../../assets/home-center/company3.png"),
    }
  },
  methods: {
    toRoute(name) {
      if (name == "") {
        this.$router.push({name: "home"})
      }
      this.$router.push({name})
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
  height: 50px;
  padding-right: 100px;
  border-right: 2px solid rgba(13, 126, 191, 0);
  /* 修改为您想要的文字大小 */
  font-size: 18px !important;
  font-weight: 700 !important;
  line-height: 50px !important;
  color: rgba(255, 255, 255, 1) !important;
}

::v-deep .el-button {
  background: linear-gradient(154.73deg, rgba(13, 126, 191, 1) 0%, rgba(112, 168, 64, 1) 100%);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
  width: 121px;
  height: 42px;
  border: 0px solid #DCDFE6;
}

::v-deep .el-button:hover {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

::v-deep .el-tabs__active-bar {
  opacity: 0.19;
  padding-left: 100%;
}

::v-deep .is-active {
  border-right: 2px solid rgba(13, 126, 191, 1);
}

.company {
  width: 1920px;
  height: 730px;
  position: relative;
  .company-background{
    width: 1920px;
    height: 730px;
  }
  .company-main {
    width: 1400px;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 85.41px;
    margin-left: 260px;

    .title {
      /** 文本1 */
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 46.34px;
      color: rgba(34, 34, 34, 1);
    }

    .text-first {
      margin-top: 30px;
      /** 文本1 */
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 23.17px;
      color: rgba(102, 102, 102, 1);
    }

    .text-second {
      margin-top: 6px;
      /** 文本1 */
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 23.17px;
      color: rgba(102, 102, 102, 1);
    }

    .detail {
      margin-top: 80px;
      width: 1400px;
      height: 341px;
      display: flex;
      justify-content: center;

      .detail-item {
        width: 466px;
        height: 341px;
        position: relative;
        .company1 {
          width: 466px;
          height: 341px;
        }
        .company2 {
          width: 466px;
          height: 341px;
        }
        .company3 {
          width: 466px;
          height: 341px;
        }
        .desc {
          position: absolute;
          top: 150px;
          left: 100px;
          /** 文本1 */
          font-size: 22px;
          font-weight: 400;
          letter-spacing: 0.44px;
          line-height: 31.86px;
          color: rgba(255, 255, 255, 1);
          text-align: left;
          .desc-next {
            margin-left: 12px
          }
        }
      }
    }
  }
}

.solution {
  width: 1920px;
  height: 838px;
  position: relative;
  .background {
    width: 1920px;
    height: 838px;
  }
  .solution-main {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 92.41px;

    .title {
      /** 文本1 */
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 46.34px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
    }

    .solution-tab {
      width: 1021px;
      height: 417.63px;
      margin-top: 150px;
      margin-left: 320px;
      .tab {
        height: 500px;
      }
		.eMoveTool{
			margin-top: 30px;
		}
      .tab-title {
        margin-left: 50px;
        /** 文本1 */
        font-size: 36px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 52.13px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;
      }
		.eMoveTab-text{
			margin-top: 50px;
			margin-left: 50px;
			width: 722.26px;
			height: 80px;
			opacity: 0.85;
			/** 文本1 */
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0px;
			line-height: 23.17px;
			color: rgba(255, 255, 255, 1);
			text-align: left;
			vertical-align: top;
		}
      .tab-text {
        margin-top: 50px;
        margin-left: 50px;
        width: 722.26px;
        height: 100px;
        opacity: 0.85;
        /** 文本1 */
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;
      }
		.eMoveButtonRoute{
			margin-top: 10px;
			margin-left: 50px;
			text-align: left;
		}
      .buttonRoute {
        margin-top: 80px;
        margin-left: 50px;
        text-align: left;
      }
    }
  }

}

.map {
  width: 1920px;
  height: 1000px;
  .map-img{
    width: 1400px;
    height: 800px;
  }
  .map-title {
    margin-top: 5%;
    margin-bottom: 5%;
    /** 文本1 */
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 46.34px;
    color: rgba(34, 34, 34, 1);
    text-align: center;
  }
}

.honor {
  width: 1920px;
  height: 473px;
  position: relative;
  .honor-background {
    width: 1920px;
    height: 473px;
  }
  .text {
    width: 1920px;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 30px;
    /** 文本1 */
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 46.34px;
    color: rgba(34, 34, 34, 1);
  }

  .honor-list {
    width: 1920px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 180px;
    .honor-item1 {
      padding-left: 260px;
      .honor-img1 {
        width: 158px;
        height: 140px;
      }
      .honor-text1 {
        margin-top: 12px;
        /** 文本1 */
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 21.72px;
        color: rgba(51, 51, 51, 1);
        text-align: center;
      }
    }
    .honor-img2 {
      width: 158px;
      height: 140px;
    }
    .honor-text2 {
      margin-top: 12px;
      /** 文本1 */
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 21.72px;
      color: rgba(51, 51, 51, 1);
      text-align: center;
    }
    .honor-img3 {
      width: 158px;
      height: 140px;
    }
    .honor-text3 {
      margin-top: 12px;
      /** 文本1 */
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 21.72px;
      color: rgba(51, 51, 51, 1);
      text-align: center;
    }
    .honor-img4 {
      width: 158px;
      height: 140px;
    }
    .honor-text4 {
      margin-top: 12px;
      /** 文本1 */
      font-size: 15px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 21.72px;
      color: rgba(51, 51, 51, 1);
      text-align: center;
    }
    .honor-item5 {
      padding-right: 260px;
      .honor-img5 {
        width: 158px;
        height: 140px;
      }
      .honor-text5 {
        margin-top: 12px;
        /** 文本1 */
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 21.72px;
        color: rgba(51, 51, 51, 1);
        text-align: center;
      }
    }
  }
}


</style>